import { FilterDto, isNullOrEmpty } from '@nuis/common';
import { DateRange } from '@nuis/forms';
import { DateTime } from 'luxon';

export function toTextFilterDto(value: string | null | undefined): FilterDto<string>[] | null {
    return !isNullOrEmpty(value) ? [{ value: value ?? '', matchMode: 'contains' }] : null;
}

export function toSingleSelectFilterDto<T = string>(value: T | null | undefined): FilterDto<T>[] | null {
    return value != null ? [{ value: value, matchMode: 'equals' }] : null;
}

export function toMultiSelectFilterDto<T = string>(value: T[] | null | undefined): FilterDto<T[]>[] | null {
    return value != null ? [{ value: value, matchMode: 'in' }] : null;
}

export function toDateFilterDto(value: DateTime | null | undefined): FilterDto<DateTime | null>[] | null {
    return value != null ? [{ value: value, matchMode: 'dateIs' }] : null;
}

export function toDateRangeFilterDto(value: DateRange | null | undefined): FilterDto<string | null>[] | null {
    return value != null
        ? [
              { value: value?.start?.toISODate() ?? null, matchMode: 'dateAfter' },
              { value: value?.end?.toISODate() ?? null, matchMode: 'dateBefore' },
          ]
        : null;
}
