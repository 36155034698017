import { computed, inject, Signal } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { serbianBankNumberValidator } from '@nuis/common';
import { IbanPipe, ibanValidator } from 'ngx-iban';

export interface BankNumberConfig {
    validator: ValidatorFn;
    formatter: (value: string | null) => string | null;
}

export function injectBankNumberConfig(isSerbia: Signal<boolean>): Signal<BankNumberConfig> {
    const ibanPipe = inject(IbanPipe);

    const ibanConfig: BankNumberConfig = {
        validator: ibanValidator(),
        formatter: (value) => ibanPipe.transform(value),
    };

    const serbianBankNumberConfig: BankNumberConfig = {
        validator: serbianBankNumberValidator,
        formatter: (value) => value,
    };

    return computed<BankNumberConfig>(() => {
        return isSerbia() ? serbianBankNumberConfig : ibanConfig;
    });
}
